import React, { useEffect, useState } from "react";
import Select, { useStateManager } from 'react-select';
import { Col, Input, Row } from "reactstrap";


interface InvestorTypeProps {
    value: any,
    label: string,
    
}

const investorGoalsType: InvestorTypeProps[] = [
    {
        "value": null,
        "label": null
    },
    {
        "value":"01",
        "label":"Apresiasi Harga",
    },
    {
        "value":"02",
        "label":"Spekulasi",
    },
    {
        "value":"03",
        "label":"Penghasilan",
    },
    {
        "value":"04",
        "label":"Investasi Jangka Panjang",
    },
    {
        "value":"05",
        "label":"Lainnya",
    },

] 

const getValueOfInvestmentGoal = (value: string) => investorGoalsType.filter(e => e.value == value)[0]

const InvestmentGoalField = ({isWni,form, isUpdate, setUpdate}) => {
    const [prevValue, setPrevValue] = useState(form.value?.etInvestmentGoal);
    const [prevTextValue, setPrevTextValue] = useState(form.value?.etOtherInvestmentGoal);
    const [selectedValue, setSelectedValue] = useState(getValueOfInvestmentGoal(form.value?.etInvestmentGoal));
    const [showOtherText, setShowOtherText] = useState(false)
    

    useEffect(() => {
        if(selectedValue?.value == "05") {
            setShowOtherText(true)
        }else {
            setShowOtherText(false)
        }

    },[selectedValue] )

    useEffect(() => {
        if(form.values.etInvestmentGoal != null) {
            setPrevValue(getValueOfInvestmentGoal(form.values.etInvestmentGoal))
            setPrevTextValue(form.values?.etOtherInvestmentGoal)
            setSelectedValue(getValueOfInvestmentGoal(form.values.etInvestmentGoal))
            if(form.values.etInvestmentGoal != "05") {
                form.values.etOtherInvestmentGoal = getValueOfInvestmentGoal(form.values.etInvestmentGoal).label
            }
        }
    }, [form.values])


    return (
        <>
            <Row style={{ marginTop: '18px' }}>
                <Col>{isWni ? 'Tujuan Investasi' : 'Invesment Goal'}</Col>
                <Col 
                    className={'d-flex justify-content-end'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        if (isUpdate) {
                            setUpdate(false);
                            setSelectedValue(prevValue)
                            form.values.etOtherInvestmentGoal = prevTextValue;
                        } else {
                            setUpdate(true);
                            // setSelectedValue(getValueOfInvestmentGoal(form.values.etInvestmetnGoal))
                        }}}>
                    {isUpdate ? 'Cancel' : 'Edit'}
                </Col>
            </Row>
            <Row style={{ marginTop: '8px' }}>
                <div>
                    <Select
                        name={'etInvestmentGoal'}
                        className="basic-single"
                        placeholder={''}
                        value={selectedValue}
                        options={investorGoalsType}
                        isSearchable={false}
                        styles={{
                            option: (
                            styles,
                            { isFocused, isSelected }
                            ) => ({
                            ...styles,
                            border: 0,
                            background: isFocused
                                ? '#f8dbc4'
                                : isSelected
                                ? '#F37F26'
                                : undefined,
                            zIndex: 1,
                            }),
                        }}
                    onChange={(e) => {
                        setSelectedValue(e)

                        form.values.etInvestmentGoal = e.value
                        if(e.value === "05") {
                            form.values.etOtherInvestmentGoal = null
                        }else {
                            form.values.etOtherInvestmentGoal = e.label
                        }
                        

                        // form.handleChange({
                        // target: {
                        //     name: 'etOtherInvestmentGoal',
                        //     value: e.label,
                        // }});
                    }}
                    // value={"01"}
                    isDisabled={!isUpdate}
                    />
                </div>
            </Row>
            {showOtherText && <div>
                <Row style={{ marginTop: '18px' }}>
                <Col>
                    {isWni ? 'Tujuan Investasi (Lainnya)' : 'Investment Goal Others'}
                </Col>
                </Row>
                <Row style={{ marginTop: '8px' }}>
                <div>
                    <Input
                        name="etOtherInvestmentGoal"
                        className="form-control"
                        disabled={!isUpdate}
                        maxLength={30}
                        required={true}
                        onChange={form.handleChange}
                        value={form.values.etOtherInvestmentGoal}
                    />
                </div>
                </Row>
            </div>}
            
        </>
    )
}

export default InvestmentGoalField